import { useMemo } from "react";
// Models
import { IMyUser } from "app/models/User";
import { IConversation } from "app/models/ChatAI";
// Store
import { useAppSelector } from "./useStore";
// Selectors
import { selectMyUser } from "app/store/Users/Users.selectors";
import { selectConversation } from "app/store/AIChat/AIChat.selectors";

const useAiChatPermissionResolver = (conversation?:IConversation) => {
  // State
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const stateConversation = useAppSelector(selectConversation);

  const selectedConversation = conversation || stateConversation;

  const isMyConversation = useMemo(() => {
    if ( !selectedConversation ) return false;
    return selectedConversation.createdBy === myUser.id;
    // eslint-disable-next-line
  }, [myUser, selectedConversation]);

  const sharedWithMe = useMemo<boolean>(() => {
    if ( !selectedConversation ) return false;
    return !isMyConversation && !selectedConversation.private;
    // eslint-disable-next-line
  }, [isMyConversation, selectedConversation]);

  const sharedByMe = useMemo<boolean>(() => {
    if ( !selectedConversation ) return false;
    return isMyConversation && !selectedConversation.private;
    // eslint-disable-next-line
  }, [isMyConversation, selectedConversation]);

  const allowModifications = useMemo<boolean>(() => {
    if ( !selectedConversation ) return true;
    return sharedByMe || selectedConversation.private;
    // eslint-disable-next-line
  }, [isMyConversation, sharedByMe, selectedConversation]);

  return { sharedWithMe, sharedByMe, allowModifications };
}

export default useAiChatPermissionResolver;
