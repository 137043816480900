import { FC, Fragment, memo, useCallback, useMemo } from 'react';
// Models
import { IConversation, IMessage } from 'app/models/ChatAI';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selector
import { selectConversation, selectRefineParentId } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Box } from '@mui/material';
// Context
import { useMessageContext } from './Message.context';
//
import MessageSkeleton from '../MessageSkeleton';
import MessageText from './MessageText';
import MessageActions from './MessageActions';
import MessageRelevantText from './MessageRelevantText';
import MessageIngestedDocuments from './MessageIngestedDocuments';
import MessageCopyAction from './MessageCopyAction';

type Props = {
  message?: IMessage
};

const RefineMessage:FC<Props> = ({
  // Props
  message:msg
}) => {
  // State
  const conversation = useAppSelector(selectConversation) as IConversation;
  const refineParentId = useAppSelector(selectRefineParentId);

  const { refineMessages, activeIndex } = useMessageContext();

  const pagesPercent = useCallback((message:IMessage) => ({
    numberOfInputPages: message.metadata?.numberOfInputPages || 0,
    numberOfAnalyzedPages: message.metadata?.numberOfAnalyzedPages || 0
    // eslint-disable-next-line
  }), []);

  const message = useMemo(() => {
    return msg || refineMessages[activeIndex];
  }, [msg, refineMessages, activeIndex]);

  const percents = useMemo(() => {
    if ( !message ) return 0;
    const { numberOfInputPages, numberOfAnalyzedPages } = pagesPercent(message);
    if ( !numberOfInputPages && !numberOfAnalyzedPages ) return 0;
    if (
      ( numberOfInputPages !== 0 && numberOfAnalyzedPages !== 0 ) &&
      numberOfInputPages === numberOfAnalyzedPages
    ) return 100;
    return (numberOfAnalyzedPages / numberOfInputPages) * 100;
    // eslint-disable-next-line
  }, [message]);

  const refinePercents = useMemo(() => {
    const ragMaxPages = conversation.settings?.ragMaxPages || 0;
    if ( !message ) return 0;
    const { numberOfInputPages, numberOfAnalyzedPages } = pagesPercent(message);
    if ( !numberOfInputPages && !numberOfAnalyzedPages ) return 0;
    const numberOfLeftPages = numberOfInputPages - numberOfAnalyzedPages;
    return Math.min(ragMaxPages, numberOfLeftPages) / numberOfInputPages * 100;
    // eslint-disable-next-line
  }, [message]);

  const showRefineButton = useMemo(() => {
    const lastMessage = refineMessages[refineMessages.length - 1];
    if ( lastMessage.type !== 'ai' ) return false;
    const { numberOfInputPages, numberOfAnalyzedPages } = pagesPercent(lastMessage);
    return !Boolean(( numberOfInputPages !== 0 && numberOfAnalyzedPages !== 0 ) && numberOfInputPages === numberOfAnalyzedPages);
    // eslint-disable-next-line
  }, [refineMessages])

  const loadingRefine = refineParentId === message.parentId;
  const roundedPercents = Number(refinePercents < 1 ? percents.toFixed(2) : Math.floor(percents));
  const roundedRefinePercents = Number(refinePercents < 1 ? refinePercents.toFixed(2) : Math.floor(refinePercents));

  const textProps = { message, percents };
  const relevantTextProps = { message, percents, roundedPercents, roundedRefinePercents };
  const injestedDocumentsProps = { message };
  const copyActionProps = { message };
  const actionsProps = { message, showRefineButton };

  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        gap: 2
      }}>
        <Box sx={{ width: '100%' }}>
          <MessageText {...textProps} />
          <MessageRelevantText {...relevantTextProps} />
        </Box>
        {loadingRefine ? <MessageSkeleton /> : null}
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        pt: 2,
        ml: 'auto'
      }}>
        <MessageIngestedDocuments {...injestedDocumentsProps} />
        <MessageCopyAction {...copyActionProps } />
        <MessageActions {...actionsProps } />
      </Box>
    </Fragment>
  )
}

export default memo(RefineMessage);
