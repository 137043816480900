import IOperation from "app/models/Operation";
import AccountStatuses from "app/types/AccountStatuses";
import FileFormats from "app/types/FileFormats";
import Permissions from "app/types/Permissions";
import { TemplateDataType, TemplateLayout, TemplateType } from "app/types/Template";
import { getI18n } from "react-i18next";

const i18n = getI18n();
i18n.setDefaultNamespace('common');

export const toCamelCase = (text:string) => {
  text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
  return text.substring(0, 1).toLowerCase() + text.substr(1);
}

export const toSize = (bytes:number, decimals = 2) => {
  if ( bytes === 0 ) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const toNumberWithCommas = (num:number, decimals:boolean = true) => {
  const fractionDigits = decimals ? 2 : 0;
  return num.toFixed(fractionDigits).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getSearchParam = (search:string, field:string):string|null => {
  const searchParams = new URLSearchParams(search);
  return searchParams.get(field) || null;
}

export const getFileExtFromFileName = (name:string):FileFormats => {
  const nameParts = name.split('.');
  return (nameParts.at(-1) || '').toLowerCase() as FileFormats;
}

export const parseToken = (token:string) => {
  const tokenId = token.split('.')[1];
  try {
    return JSON.parse(atob(tokenId));
  } catch(e) {}
  return null;
}

export const downloadFile = async (response:Response, file:{
  name: string;
  ext: FileFormats
}) => {
  const blob:Blob = await response.blob();
  const filename = response.headers.get('X-Filename');
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = filename ? decodeURIComponent(filename) : `${file.name}-${new Date().getTime()}.${file.ext}`;
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const permissionToNormalLabel = (permission:Permissions):string => {
  if ( permission.includes(':') ){
    return permission.split(':').reduce((acc:string, cur:string, index:number) => {
      const text = index === 0 ? `${cur.charAt(0).toUpperCase() + cur.slice(1)}` : ` ${cur}`;
      acc += text;
      return acc;
    }, '');
  }
  const label = permission.replace(/([A-Z])/g, ' $1').toLowerCase();
  return label.charAt(0).toUpperCase() + label.slice(1);
}

export const trimValue = (value:string) => {
  return value.replace(/\s+/g, ' ').trim();
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const textToCapitalizeCase = (text: string) => {
  return text.replace(/^\w/, c => c.toUpperCase());
}

export const camelCaseToNormalText = (text:string) => {
  const spacedString = text.replace(/([A-Z])/g, ' $1');
  const normalizedText = spacedString.trim();
  return normalizedText.charAt(0).toUpperCase() + normalizedText.slice(1);
}

export const getFontSize = (value: string):string|undefined => {
  switch (value) {
    case '1':
      return '36pt';
    case '2':
      return '28pt';
    case '3':
      return '24pt';
    case '4':
      return '20pt';
    case '5':
      return '18pt';
    case '6':
      return '16pt';
    default:
      return undefined;
  }
}

export const hasOperationByPathType = (operations:IOperation[], pathType:string) => {
  let hasOperation = false;
  for ( let operation of operations ){
    if ( operation.path.includes(pathType) ){
      hasOperation = true;
      break;
    }
  }
  return hasOperation;
}

// For testing
type Types = 'element' | 'control' | 'action' | 'active';
type Elem = 'autocomplete' | 'input' | 'select' | 'checkbox' | 'fab' | 'button' | 'button-tab' | 'list' | 'menu-item' | 'chip' | 'box-color' | 'rte';

export const generateAttrForTesting = (type:Types, elem:Elem, value:string | boolean | undefined) => {
  value = typeof value === 'string'
    ? toCamelCase(value)
    : typeof value === 'boolean' ? value : elem;
  return { [`data-${type}-${elem}`]: value }
};

export const getAccountStatusLabel = (status:AccountStatuses):string => {
  const accountStatuses = {
    [AccountStatuses.Active]: i18n.t('pages.accountManagerPages.companyAccountsPage.active'),
    [AccountStatuses.Disabled]: i18n.t('pages.accountManagerPages.companyAccountsPage.disabled'),
    [AccountStatuses.Suspended]: i18n.t('pages.accountManagerPages.companyAccountsPage.suspended')
  }

  return accountStatuses[status];
}

export const getTemplateTypeLabel = (type: TemplateType):string => {
  const templateTypeLabels:Record<TemplateType, string> = {
    [TemplateType.Data]: i18n.t('dialogs.templateWizard.data'),
    [TemplateType.Text]: i18n.t('dialogs.templateWizard.text')
  }

  return templateTypeLabels[type];
}

export const getTemplateDataTypeLabel = (dataType: TemplateDataType):string => {
  const templateDataTypeLabels:Record<TemplateDataType, string> = {
    [TemplateDataType.Episode]: i18n.t('dialogs.templateWizard.episode'),
    [TemplateDataType.Document]: i18n.t('dialogs.templateWizard.documents')
  }

  return templateDataTypeLabels[dataType];
}

export const getTemplateLayoutLabel = (layout: TemplateLayout):string => {
  const templateLayoutLabels:Record<TemplateLayout, string> = {
    [TemplateLayout.Chart]: i18n.t('dialogs.templateWizard.chart'),
    [TemplateLayout.Custom]: i18n.t('dialogs.templateWizard.custom'),
    [TemplateLayout.Table]: i18n.t('dialogs.templateWizard.table')
  }

  return templateLayoutLabels[layout];
}

const extractPathFromUrl = (input:string) => {
  try {
    const url = new URL(input);
    return url.pathname;
  } catch (e) {
    return input;
  }
}

export const urlPathMatches = (regExp:RegExp, method:string, url:string) => {
  const path = extractPathFromUrl(url);
  const route = `${method.toUpperCase()} ${path}`;
  return regExp.test(route);
}
