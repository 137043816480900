import { FC } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
// Models
import IInsuranceCase from 'app/models/Case';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInsuranceCase, selectCaseAiChatEnabled } from 'app/store/Cases/Cases.selectors';
import { selectConversation } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Box } from '@mui/material';
// Hooks
import useAiChatPermissionResolver from 'app/hooks/useAiChatPermissionResolver';
// 
import ConversationInitialRoute from './ConversationInitialRoute';
import ConversationMessageList from './ConversationMessageList';
import ConversationMessageForm from './ConversationMessageForm';

const AIChatRoutes:FC = () => {
  const { path, url } = useRouteMatch<{ path:string; url:string }>();

  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const caseAiChatEnabled = useAppSelector(selectCaseAiChatEnabled);
  const conversation = useAppSelector(selectConversation);

  const { allowModifications } = useAiChatPermissionResolver();

  const showConversationMessages = caseAiChatEnabled && insuranceCase.processing?.status === 'ready';
  const isNonConversationOrEditableSelected = !conversation || allowModifications
  const showConversationForm = caseAiChatEnabled && insuranceCase.processing?.status === 'ready' && isNonConversationOrEditableSelected;

  const renderConversationMessageListRoute = () => (
    showConversationMessages ? (
      <ConversationMessageList />
    ) : (
      <Redirect to={url} />
    )
  );

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
        <Switch>
          <Route exact path={path} component={ConversationInitialRoute} />
          <Route path={`${path}/:conversationId`} render={renderConversationMessageListRoute} />
        </Switch>
      </Box>
      {showConversationForm ? (
        <ConversationMessageForm />
      ) : null}
    </Box>
  )
}

export default AIChatRoutes;
