// ToDO
import config from '../../config';
// Types
import RequestMethods from 'app/types/RequestMethods';
import GrantTypes from 'app/types/GrantTypes';
// Models
import IAuthCredential from 'app/models/AuthCredential';
// Service
import LocalStorageService from 'app/services/LocalStorage.service';
import IdempotencyKeyService from 'app/services/IdempotencyKey.service';
// Utilities
import { uuidv4 } from './Utilities';

const excludedURLs:string[] = ['/auth/token'];

export async function $get(
  path:string,
  params:any = {},
  args:RequestInit = {},
):Promise<Response> {
  const initialArgs = { method: RequestMethods.Get }
  if ( Object.keys(params).length ){
    const queryParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      const value = params[key];
      if (
        value !== '' &&
        value !== 'all' &&
        ( typeof value !== 'undefined' && value !== null )
      ) queryParams.append(key, params[key]);
    });
    path = `${path}?${queryParams}`;
  }
  return await _http(path, {...initialArgs, ...args});
};

export async function $post(
  path:string,
  body?:any,
  args:RequestInit = {}
):Promise<Response> {
  const initialArgs = {
    method: RequestMethods.Post,
    body: body instanceof FormData ? body : JSON.stringify(body)
  };
  if ( !(body instanceof FormData) ){
    args['headers'] = { 'Content-Type': 'application/json' };
  }
  return await _http(path, {...initialArgs, ...args});
};

export async function $put(
  path:string,
  body:any,
  args:RequestInit = {}
):Promise<Response> {
  const initialArgs = {
    method: RequestMethods.Put,
    body: body instanceof FormData ? body : JSON.stringify(body)
  };
  if ( !(body instanceof FormData) ){
    args['headers'] = { 'Content-Type': 'application/json' };
  }
  return await _http(path, {...initialArgs, ...args});
};

export async function $patch(
  path:string,
  body:any,
  args:RequestInit = {}
):Promise<Response> {
  const initialArgs = {
    method: RequestMethods.Patch,
    body: JSON.stringify(body)
  };
  args['headers'] = { 'Content-Type': 'application/json' };
  return await _http(path, {...initialArgs, ...args});
};

export async function $delete(
  path:string,
  body?:any,
  args:RequestInit = {}
):Promise<Response> {
  const initialArgs = { method: RequestMethods.Delete };
  if ( body ){
    args['headers'] = { 'Content-Type': 'application/json' };
    args['body'] = JSON.stringify(body);
  }
  return await _http(path, {...initialArgs, ...args});
};

export async function resolvePath(path:string, params:any = {}):Promise<any> {
  const accessToken = await _getAccessToken();

  const queryParams = new URLSearchParams();

  if ( accessToken ){
    queryParams.append('X-Auth-Token', accessToken);
  }

  if ( Object.keys(params).length ){
    Object.keys(params).forEach((key:string) => {
      if ( params[key] !== '' ) queryParams.append(key, params[key]);
    });
    path = `${path}?${queryParams}`;
  }
  return Promise.resolve(`${config.apiUrl}${path}`);
}

const _http = async (path:string, args:RequestInit = {}):Promise<Response> => {
  const accessTokenType = LocalStorageService.getAccessTokenType();

  const accessToken = await _getAccessToken();

  const key = uuidv4();

  IdempotencyKeyService.addKey(key);

  args.headers = {
    ...args.headers,
    'Idempotency-Key': key
  };

  if ( accessToken && !excludedURLs.includes(path) ){
    args.headers = {
      ...args.headers,
      'Authorization': `${accessTokenType} ${accessToken}`
    }
  }

  const url:string = path.includes('/api') ? path : `${config.apiUrl}${path}`;
  const request:RequestInfo = new Request(url, args);
  const response:Response = await fetch(request, args);

  if ( !response.ok ){
    const errors = await response.json();
    throw new Error(JSON.stringify({
      ...errors,
      // This check ensures that if a given path already includes /api, it remains unchanged.
      // If the path does not include /api, the function prepends /api to the path.
      // example: mesage api url or ai-chat api url ( this is external api urls )
      path: path.includes('/api') ? path : `/api${path}`,
      method: request.method,
      statusCode: response.status
    }));
  }

  return response;
}

let accessToken:string | null = null;
let refreshToken:string | null = null;

let isRefreshTokenRequestLocked = false;

const _getAccessToken = async ():Promise<string | null> => {
  const isAccessTokenExpired = LocalStorageService.isAccessTokenExpired();

  refreshToken = LocalStorageService.getRefreshToken();
  accessToken = LocalStorageService.getAccessToken();

  if ( !isAccessTokenExpired ) return Promise.resolve(accessToken);
  if ( !refreshToken ) return Promise.resolve(null);

  return new Promise(async (resolve) => {
    if ( !isRefreshTokenRequestLocked ){
      isRefreshTokenRequestLocked = true;

      try {
        accessToken = await _updateAccessToken();
        resolve(accessToken);
      } finally {
        isRefreshTokenRequestLocked = false;
      }
    } else {
      const checkIsRefreshTokenRequestLocked = () => {
        console.log('Checking is refresh token locked');

        let timeout;

        if ( isRefreshTokenRequestLocked ){
          timeout = setTimeout(checkIsRefreshTokenRequestLocked, 100);
          return;
        }

        console.log('New access token', accessToken);
        console.log('Refresh token released')

        clearTimeout(timeout);

        resolve(accessToken);
      }

      checkIsRefreshTokenRequestLocked();
    }
  });
}

const _updateAccessToken = async () => {
  try {
    const authCredential:IAuthCredential = await _getRefreshToken();

    LocalStorageService.setAuthCredential(authCredential);

    accessToken = authCredential.accessToken;
    refreshToken = authCredential.refreshToken as string;

    return accessToken;
  } catch(error){
    console.log(error);
  } finally {
    isRefreshTokenRequestLocked = false;
  }

  return null
}

const _getRefreshToken = async () => {
  const response = await fetch(`${config.apiUrl}/auth/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      grantType: GrantTypes.RefreshToken,
      refreshToken
    })
  });
  if ( !response.ok ) throw Error('Something wen`t wrong with `refreshToken`');
  return response.json();
}
