import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { patchInsuranceCase } from 'app/store/Cases/Cases.async';
// Actions
import { CasesActions } from 'app/store/Cases/Cases.slice';
// Selectors
import { selectInsuranceCase, selectCaseAiChatEnabled } from 'app/store/Cases/Cases.selectors';
import { selectHasAnyDocumentOcred } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Alert, Box, Typography } from '@mui/material';
// Components
import { LoadingButton } from 'app/components/Mui';
import { Message } from 'app/components/Utilities';
// 
import ConversationInitialPrompts from './ConversationInitialPrompts';

const ConversationInitialRoute:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const hasAnyDocumentOcred = useAppSelector(selectHasAnyDocumentOcred);
  const caseAiChatEnabled = useAppSelector(selectCaseAiChatEnabled);

  const [ loading, setLoading ] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      await dispatch(patchInsuranceCase({
        insuranceCaseId: insuranceCase.id,
        data: {
          version: insuranceCase.version,
          settings: {
            ...(insuranceCase.settings || {}),
            aiChatEnabled: true
          }
        },
        message: 'AI Chat is enabled'
      })).unwrap();

      dispatch(CasesActions.setInsuranceCaseProcessingStatus('processing'));

    } catch (error){
      console.error(error)
    } finally {
      setLoading(false);
    }
  }

  if ( !caseAiChatEnabled ) return (
    <Box sx={{
      maxWidth: 1024,
      p: 4,
      mx: 'auto'
    }}>
      {!hasAnyDocumentOcred ? (
        <Alert sx={{ mb: 4 }} color="warning">Looks like there aren't any processed documents yet! Add at least one document and get it processed to get started with AI Chat</Alert>
      ) : null}
      <Typography sx={{ fontWeight: 700 }} variant="subtitle1">Enable AI Chat to assist you in resolving cases faster and more efficiently.</Typography>
      <Typography sx={{ mb: 4, fontWeight: 700 }} variant="subtitle1">Here's how it works:</Typography>
      <Typography sx={{ color: 'rgba(0,0,0,0.54)' }} display="block" variant="body1">- When enabled, AI Chat will review all case documents</Typography>
      <Typography sx={{ color: 'rgba(0,0,0,0.54)' }} display="block" variant="body1">- Newly uploaded documents will be automatically reviewed by AI Chat</Typography>
      <Typography sx={{ color: 'rgba(0,0,0,0.54)' }} display="block" variant="body1">- Based on the documents selected for specific conversation, AI Chat will suggest answers and relevant information to your questions.</Typography>
      <Typography sx={{ color: 'rgba(0,0,0,0.54)' }} display="block" variant="body1">- Your documents are always private. They are not shared with anyone outside and are not used for training purposes</Typography>
      <Box sx={{ pt: 4, textAlign: 'center' }}>
        <LoadingButton
          name="enable ai chat"
          loading={loading}
          disabled={!hasAnyDocumentOcred}
          onClick={handleClick}
          variant="contained"
        >Enable AI Chat</LoadingButton>
      </Box>
    </Box>
  );
  if ( insuranceCase.processing?.status !== 'ready' ) return (
    <Message text="Wait for case to be in status Ready to create new conversations. You can still access existing conversations." />
  );
  return (
    <Fragment>
      <Message text={t('pages.staffPages.caseDetailsPage.initialConversationText')} />
      <ConversationInitialPrompts />
    </Fragment>
  );
}

export default ConversationInitialRoute;
