import { FC, Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createConversation, createQuestion } from 'app/store/AIChat/AIChat.async';
// Actions
import { AIChatActions } from 'app/store/AIChat/AIChat.slice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectConversation, selectLoadingMessage, selectStatusMessage } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Box, Tooltip } from '@mui/material';
// Icons
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
// Components
import { IconButton, Input, LoadingButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import AIPromptDialog from './AIPromptDialog';

interface IFormData {
  value: string;
  rows: number;
}

const ConversationMessageForm:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const conversation = useAppSelector(selectConversation);
  const loadingMessage = useAppSelector(selectLoadingMessage);
  const statusMessage = useAppSelector(selectStatusMessage);

  const { open, toggle } = useToggle();

  const [ loading, setLoading ] = useState(false);

  const { register, reset, setValue, watch } = useForm<IFormData>({
    defaultValues: {
      value: '',
      rows: 1,
    },
  });

  const watchValue = watch('value');
  const watchRows = watch('rows');

  const resetForm = () => {
    if ( loading ) setLoading(false);
    reset({ value: '', rows: 1 });
    dispatch(AIChatActions.setStatusMessage('initial'));
  }

  useEffect(() => {
    if ( statusMessage === 'sent' ) resetForm();
    // eslint-disable-next-line
  }, [statusMessage]);

  useEffect(() => {
    if ( !conversation && loading ) resetForm();
    // eslint-disable-next-line
  }, [conversation, loading]);

  const appendValueWithRows = (value:string) => {
    const lineCount = (value.match(/\n/g) || []).length + 1;
    setValue('value', value);
    setValue('rows', Math.min(lineCount, 4));
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Hz how it works, but when I remove this line, "Shift+Enter" stop works
    if ( event.key === 'Enter' && event.shiftKey ){
      // event.preventDefault(); // Prevent default behavior (new line)
    } else if ( event.key === 'Enter' ){
      event.preventDefault(); // Prevent default behavior (form submission)
      handleClick();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    appendValueWithRows(value);
  };

  const handleClick = async () => {
    if ( !watchValue || !watchValue.trim() ) return;

    setLoading(true);

    try {
      if ( conversation ){
        const conversationId = conversation.id;
        await dispatch(createQuestion({
          conversationId,
          data: { question: watchValue }
        }));
      } else {
        await dispatch(createConversation({
          caseId: insuranceCase.id,
          question: watchValue
        }));
      }
    } catch(error){
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const disableInput = insuranceCase?.processing?.status !== 'ready' || loadingMessage;
  const disableButton = insuranceCase?.processing?.status !== 'ready' || (loadingMessage && !loading);

  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        maxWidth: 920,
        mx: 'auto',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        py: 4,
        px: 8
      }}>
        <input {...register('rows')} type="hidden" value={watchRows} />
        <Tooltip title="Prompt Hub">
          <IconButton
            name="Prompt Hub"
            onClick={toggle}
          ><AddCircleOutlineIcon /></IconButton>
        </Tooltip>
        <Input
          {...register('value')}
          label={t('labels.message')}
          value={watchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          multiline
          rows={watchRows}
          margin="none"
          size="small"
          disabled={disableInput}
        />
        <LoadingButton
          name="Send message"
          loading={loading}
          disabled={disableButton}
          onClick={handleClick}
          variant="contained"
          color="primary"
        >{t('labels.send')}</LoadingButton>
      </Box>
      {open ? (
        <AIPromptDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default ConversationMessageForm;
