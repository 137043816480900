import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IConversation, IMessage } from "app/models/ChatAI";
// Async
import {
  createEmbeddings,
  getConversations, getConversation, createConversation, updateConversation, deleteConversation, createQuestion,
  refineAnswer
} from './AIChat.async';

interface IState {
  conversations: IConversation[] | null;
  conversation: IConversation | null;
  loading: boolean;
  loadingMessage: boolean;
  statusMessage: 'initial' | 'error' | 'sent';
  // 
  refineParentId: string | null;
  disableGetConversation: boolean;
}

const initialState:IState = {
  conversations: null,
  conversation: null,
  loading: false,
  loadingMessage: false,
  statusMessage: 'initial',
  // 
  refineParentId: null,
  disableGetConversation: false
};

const slice = createSlice({
  name: Reducers.AIChat,
  initialState,
  reducers: {
    addMessageFromSocket: (state:IState, action:PayloadAction<{
      conversationId:string;
      idempotencyKey:string;
      data:any
    }>) => {
      const { conversationId, idempotencyKey, data } = action.payload;
      if ( state.conversation && state.conversation.id === conversationId ){
        const message = generateAIMessage(conversationId, idempotencyKey, data);
        state.conversation = {
          ...state.conversation,
          messages: updateMessages(state.conversation.messages || [], message)
        };
        state.loadingMessage = false;
        state.statusMessage = 'sent';
        // 
        state.refineParentId = null;
      }
    },
    addErrorMessageFromAI: (state:IState, action:PayloadAction<IMessage>) => {
      if ( state.conversation ){
        const reversedMessages = [...(state.conversation.messages || [])].reverse();
        const updatedMessages = reversedMessages.map((message, index) => {
          if ( message.type === 'human' && index === 0 ){
            return { ...message, error: true };
          }
          return message;
        }).reverse();
        state.conversation = {
          ...state.conversation,
          messages: [...updatedMessages, action.payload]
        }
      }
    },
    setConversation: (state:IState, action:PayloadAction<{ conversation: IConversation; humanMessageText:string | undefined }>) => {
      const { conversation, humanMessageText } = action.payload;
      const messages = conversation.messages || [];
      const updatedMessages = humanMessageText
        ? [...messages, generateHumanMessage(conversation.id, humanMessageText)]
        : messages
      ;
      state.conversation = {
        ...conversation,
        messages: updatedMessages
      };
      state.loadingMessage = true;
    },
    setStatusMessage: (state:IState, action: PayloadAction<'initial' | 'error' | 'sent'>) => {
      state.statusMessage = action.payload;
    },
    setDisableGetConversation: (state:IState, action:PayloadAction<boolean>) => {
      state.disableGetConversation = action.payload;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder){
    // Create embeddings
    builder.addCase(createEmbeddings.pending, (state) => {
      state.loading = true;
    });
    // Get conversations
    builder.addCase(getConversations.pending, (state) => {
      state.conversations = null;
    });
    builder.addCase(getConversations.fulfilled, (state, action:PayloadAction<IConversation[]>) => {
      state.conversations = action.payload;
    });
    builder.addCase(getConversations.rejected, (state) => {
      state.conversations = [];
    });
    // Get conversation
    builder.addCase(getConversation.pending, (state) => {
      state.conversation = null;
    });
    builder.addCase(getConversation.fulfilled, (state, action:PayloadAction<IConversation>) => {
      state.conversation = action.payload;
    });
    // Create conversation
    builder.addCase(createConversation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createConversation.fulfilled, (state, action:PayloadAction<IConversation>) => {
      if ( state.conversations ){
        state.conversations = [...state.conversations, action.payload]
      }
    });
    // Update conversation
    builder.addCase(updateConversation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateConversation.fulfilled, (state, action:PayloadAction<IConversation>) => {
      if ( state.conversation && state.conversation.id === action.payload.id ){
        state.conversation = {...state.conversation, ...action.payload};
      }
      if ( state.conversations ){
        state.conversations = state.conversations.map((conversation:IConversation) => {
          if ( conversation.id === action.payload.id ) return action.payload;
          return conversation;
        });
      }
    });
    // Delete conversation
    builder.addCase(deleteConversation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteConversation.fulfilled, (state, action:PayloadAction<string>) => {
      if ( state.conversations ){
        state.conversations = state.conversations.filter((conversation:IConversation) => conversation.id !== action.payload)
      }
    });
    // Create question
    builder.addCase(createQuestion.pending, (state, action:any) => {
      if ( state.conversation ){
        const { conversationId, data } = action.meta.arg;
        const messages = state.conversation.messages || [];
        const humanMessage = generateHumanMessage(conversationId, data.question);
        state.conversation = {
          ...state.conversation,
          messages: [...messages, humanMessage]
        };
      }
      state.loadingMessage = true;
    });
    builder.addCase(createQuestion.fulfilled, (state, action:PayloadAction<IMessage>) => {
      if ( state.conversation ){
        state.conversation = {
          ...state.conversation,
          messages: updateMessages(state.conversation.messages || [], action.payload)
        };
      }
    });
    // Refine answer
    builder.addCase(refineAnswer.pending, (state, action:any) => {
      const { parentId } = action.meta.arg;
      state.refineParentId = parentId;
    });
    builder.addCase(refineAnswer.fulfilled, (state, action:PayloadAction<IMessage>) => {
      if ( state.conversation ){
        state.conversation = {
          ...state.conversation,
          messages: [...(state.conversation.messages || []), action.payload]
        };
      }
    });
    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false;
      }
    );
  }
});

export const AIChatActions = slice.actions;

export default slice.reducer;

// When a message is received from a push notification or from a response
// we need to pass `parentid` if it is the first message from `human`
// and delete a duplicate message using `idempotencyKey`
const updateMessages = (messages:IMessage[], message:IMessage):IMessage[] => {
  const clonedMessages = [...messages]; // avoid mutation
  const updatedMessages = assignParentIdToFirstHumanMessage(clonedMessages, message);
  const foundMessage = findMessageByIdempotencyKey(updatedMessages, message.idempotencyKey as string);
  return foundMessage
    ? updatedMessages.map((m:IMessage) => {
        if ( !m.idempotencyKey ) return m;
        return m.idempotencyKey === message.idempotencyKey ? { ...m, ...message } : m;
      })
    : [...updatedMessages, message];
};

const assignParentIdToFirstHumanMessage = (messages:IMessage[], message:IMessage):IMessage[] => {
  // First question doesn`t have `id`
  // So in this case we will set `id` from first answer from `parentId`
  for ( let i = messages.length - 1; i >= 0; i-- ){
    if ( !messages[i].id && messages[i].type === 'human' ){
      messages[i] = {
        ...messages[i],
        id: message.parentId
      };
      break;
    }
  }
  return messages;
};

const findMessageByIdempotencyKey = (messages:IMessage[], idempotencyKey:string):IMessage | undefined => {
  return messages ? messages.find((message:IMessage) => message.idempotencyKey === idempotencyKey) : undefined;
};

const generateHumanMessage = (conversationId:string, text:string):IMessage => ({
  createdOn: new Date().toString(),
  conversationId,
  text,
  type: 'human'
});

const generateAIMessage = (conversationId:string, idempotencyKey:string, data:any) => ({
  ...data,
  createdOn: new Date().toString(),
  conversationId,
  idempotencyKey,
  type: 'ai'
})
