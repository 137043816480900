import { FC } from 'react';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAIChatSidebarOpen } from 'app/store/AppUI/AppUI.selectors';
// Mui
import { Theme, Box } from '@mui/material';
// Constants
import { SIDEBAR_WIDTH } from 'app/App.constants';
// 
import AIChatToolbar from './AIChatToolbar';
import ConversationList from './ConversationList';
import AIChatDisableButton from './AIChatDisableButtonn';
import AIChatSidebarToggle from './AIChatSidebarToggle';

const AIChatSidebar:FC = () => {
  // State
  const open = useAppSelector(selectAIChatSidebarOpen);

  return (
    <Box sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      width: SIDEBAR_WIDTH,
      bgcolor: 'white',
      borderRight: (theme:Theme) => `1px solid ${theme.palette.divider}`,
      marginLeft: open ? 0 : `-${SIDEBAR_WIDTH}px`
    }}>
      <AIChatToolbar />
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <ConversationList />
      </Box>
      <AIChatDisableButton />
      <AIChatSidebarToggle />
    </Box>
  )
}

export default AIChatSidebar;
