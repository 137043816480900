import { FC } from 'react';
// Models
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
// Mui
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
// Components
import Labels from 'app/components/Labels';

type Props = {
  aiPrompt: IPrompt;
  onClick: () => void;
  width?: number;
  height?: number;
  maxChars?: number;
  flexWrap?: string;
}

const AIPromptItem:FC<Props> = ({
  // Props
  aiPrompt, onClick, width = 'auto', height = 'auto', maxChars = 100, flexWrap = 'wrap'
}) => {
  return (
    <Card sx={{
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      width,
      height
    }}>
      <CardActionArea sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }} onClick={onClick}>
        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Typography variant="subtitle1">{truncateContent(aiPrompt.title, maxChars)}</Typography>
          <Typography sx={{
            flexGrow: 1,
            whiteSpace: 'pre-line',
            mb: 2
          }} color="GrayText" variant="body2">{truncateContent(aiPrompt.content, maxChars)}</Typography>
          {aiPrompt.labels ? (
            <Labels
              id={`prompt item ${aiPrompt.id} labels`}
              sx={{
                display: 'flex',
                flexWrap,
                gap: 2,
                overflow: 'hidden'
              }}
              labels={aiPrompt.labels}
            />
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default AIPromptItem;

const truncateContent = (content:string, maxChars:number = 100, maxRows:number = 2) => {
  let lines = content.split('\n');
  let result = [];
  let currentTextLength = 0;

  for ( let i = 0; i < lines.length; i++ ){
    let line = lines[i];
    if ( currentTextLength + line.length > maxChars ){
      let remainingChars = maxChars - currentTextLength;
      result.push(line.slice(0, remainingChars) + ' ...');
      break;
    } else {
      result.push(line);
      currentTextLength += line.length;
    }
    if ( result.length === maxRows ){
      result[maxRows - 1] = result[maxRows - 1] + ' ...';
      break;
    }
  }

  return result.join('\n');
}
